import { useDispatch, useSelector } from "react-redux";
import { setShowNotification } from "../../actions/notification";
import css from "./index.module.css";
import { ReactComponent as BellIcon } from "../../assets/icons/BellIcon.svg";
import Tool from "../HoopsViewer/ToolbarViewer/Tool";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../ProviderApp";
import { THEME_DARK, THEME_LIGHT } from "../../App.constant";

function NotificationIcon() {
  const [iconColor, setIconColor] = useState("");
  const showNotification = useSelector((state) => state.showNotification);
  const unreadNotificationsCount = useSelector(
    (state) => state.unreadNotificationsCount,
  );

  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const handleClickNotification = () => {
    dispatch(setShowNotification(!showNotification));
  };

  useEffect(() => {
    if (themeContext.themeType == THEME_LIGHT) {
      setIconColor("var(--surface-black");
    } else if (themeContext.themeType == THEME_DARK) {
      setIconColor("var(--surface-neutral)");
    }
  }, [themeContext]);

  const getNotificationValue = () => {
    if (unreadNotificationsCount.count > 99) {
      return "99+";
    }
    return unreadNotificationsCount.count;
  };

  return (
    <div
      onClick={handleClickNotification}
      className="preventNotificationClose flex relative"
    >
      {/* <img src={BellIcon} alt="logo" size={24} className={css.notification} /> */}
      <Tool
        Svg={BellIcon}
        color={iconColor}
        Tooltip="Notification"
        position="BottomCenter"
        setGradient={false}
        className={css.notification}
      />
      {unreadNotificationsCount.count > 0 && (
        <span className={css.notificationCount}>{getNotificationValue()}</span>
      )}
    </div>
  );
}

export default NotificationIcon;
