import { useEffect, useState } from "react";
import { getNameInitials } from "@CubeComponents/constants/globalFunctions";
import { getUserDetailsById } from "../../actions/userDetails";
import IconComponent from "../../cubeComponent/icons";
import AvatarComponent from "../../cubeComponent/html/avatar";
import ModalStateDialogComponent from "../../cubeComponent/html/modal/ModalWithState";
import ShimmerProfile from "./ShimmerProfile";
import { useSelector } from "react-redux";
import { createPortal } from "react-dom";

const UserProfileModal = ({ userId, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const usersData = useSelector((state) => state.userTagData);
  const userData = usersData[userId];
  const [selectedUserDataForProfile] = useState(userData.data);

  const userProfileModalHeader = () => (
    <div className="flex w-full justify-end cursor-pointer">
      <IconComponent name="close" onClick={onClose} />
    </div>
  );

  const getUserAvatarComponent = (user) => {
    let profile = null;
    let userInitial = null;
    if (user.profilePic) {
      profile = `fileSystemServer/getImage/profile/${user._id}`;
    } else {
      userInitial = getNameInitials(user);
    }
    return (
      <AvatarComponent isCircle size="XL" bgColor="var(--system-colour-2)">
        <>
          {user.profilePic ? (
            <img src={`${process.env.REACT_APP_CUBE_BACKEND}/${profile}`} />
          ) : (
            userInitial
          )}
        </>
      </AvatarComponent>
    );
  };

  const userProfileModalContent = () => (
    <div className="pt-[var(--size-20)] items-center flex flex-col gap-[var(--size-8)]">
      {isLoading ? (
        <ShimmerProfile />
      ) : (
        <>
          {getUserAvatarComponent(selectedUserDataForProfile)}
          <div className="text-[16px] text-gray-800 font-semibold">
            {selectedUserDataForProfile.fullName}
          </div>
          {selectedUserDataForProfile.designation && (
            <div className="text-[14px] text-gray-800">
              {selectedUserDataForProfile.designation}
            </div>
          )}
          {selectedUserDataForProfile.companyName && (
            <div className="text-[14px] text-gray-800">
              {selectedUserDataForProfile.companyName}
            </div>
          )}
          <div className="text-[14px] text-gray-800">
            {selectedUserDataForProfile.email}
          </div>
        </>
      )}
    </div>
  );

  return createPortal(
    <>
      {(selectedUserDataForProfile || isLoading) && (
        <ModalStateDialogComponent
          width="250"
          isVisible={!!selectedUserDataForProfile || isLoading}
          headerContent={userProfileModalHeader()}
          modelContent={userProfileModalContent()}
        />
      )}
    </>,
    document.getElementById("root"),
  );
};
export default UserProfileModal;
