import axiosWrapper from "../services/axiosWrapper";
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from "../services/constants";
import { showToastNotification } from "./toast";

export function itemsHaveError(bool) {
  return {
    type: "ITEMS_HAVE_ERROR",
    hasError: bool,
  };
}

export function setIssuesCount(data) {
  return {
    type: "SET_ISSUES_COUNT",
    data,
  };
}

export function itemsAreLoading(bool) {
  return {
    type: "ITEMS_ARE_LOADING",
    isLoading: bool,
  };
}

export function itemsFetchDataSuccess(items) {
  return {
    type: "ITEMS_FETCH_DATA_SUCCESS",
    items,
  };
}

export function itemsFetchData(url) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .get(url)
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }

        dispatch(itemsAreLoading(false));

        return response;
      })
      .then((response) => dispatch(itemsFetchDataSuccess(response.data)))
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function saveBcfId(id) {
  return {
    type: "SAVE_BCF_DATA",
    id,
  };
}

export function setBcfBufferData(data) {
  return {
    type: "SET_BCF_BUFFER_DATA",
    data,
  };
}

export function setIssuesCommentsData(data, issueId, isEdit) {
  return {
    type: "SET_ISSUES_COMMENTS_DATA",
    data,
    issueId,
    isEdit,
  };
}

export function deleteIssuesCommentsData(data, issueId) {
  return {
    type: "DELETE_ISSUES_COMMENTS_DATA",
    data,
    issueId,
  };
}

export function setIssuesData(data) {
  return {
    type: "UPDATE_ISSUES_DATA",
    data,
  };
}

export function modifyIssuesData(data) {
  return {
    type: "MODIFY_ISSUES_DATA",
    data,
  };
}

export function arrangeIssuesData(data) {
  return {
    type: "SET_ISSUES_DATA",
    data,
  };
}

export function setIssuesPinData(data) {
  return {
    type: "SET_ISSUES_PIN_DATA",
    data,
  };
}

export function setExtraIssueData(data) {
  return {
    type: "SET_EXTRA_ISSUES_DATA",
    data,
  };
}

export function setIssuesDataEmpty() {
  return (dispatch) => {
    dispatch({ type: "EMPTY_ISSUES_DATA" });
  };
}

export function mergeIssuesData(data, editId) {
  return {
    type: "MERGE_ISSUES_DATA",
    data,
    editId,
  };
}

export function removeIssueFromForm(ids, formId) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/issues/removeIssueFromForm`,
      { ids, formId },
    );
    if (response.data.status) {
      dispatch({
        type: "REMOVE_ISSUE_FROM_STATE",
        ids,
      });
      dispatch(
        showToastNotification({
          content: "All the selected Issue(s) were removed",
          type: TOAST_TYPE_SUCCESS,
        }),
      );
    } else {
      dispatch(
        showToastNotification({
          content: "Something went wrong",
          type: TOAST_TYPE_ERROR,
        }),
      );
    }

    return response.data.status;
  };
}

export function addFormIdToIssues(ids, formId) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/issues/addFormIdToIssues`,
      { ids, formId },
    );

    if (response.data.status) {
      dispatch(
        showToastNotification({
          content: "All the selected Issue(s) were added",
          type: TOAST_TYPE_SUCCESS,
        }),
      );
    } else {
      dispatch(
        showToastNotification({
          content: "Something went wrong",
          type: TOAST_TYPE_ERROR,
        }),
      );
    }
  };
}

export function mergeIssuesPinData(data) {
  return {
    type: "MERGE_ISSUES_PINDATA",
    data,
  };
}

export function setIssuesStatusesData(data) {
  return {
    type: "SET_ISSUES_STATUSES_DATA",
    data,
  };
}

export function setTasksStatusesData(data) {
  return {
    type: "SET_TASKS_STATUSES_DATA",
    data,
  };
}
export function setEstimatesStatusesData(data) {
  return {
    type: "SET_ESTIMATES_STATUSES_DATA",
    data,
  };
}

export function setFilesStatusesData(data) {
  return {
    type: "SET_FILES_STATUSES_DATA",
    data,
  };
}

export function setProjectsStatusesData(data) {
  return {
    type: "SET_PROJECTS_STATUSES_DATA",
    data,
  };
}

export function updateTaskData(data) {
  return {
    type: "UPDATE_TASK_DATA",
    data,
  };
}

export function removeTaskData(data) {
  return {
    type: "REMOVE_TASK_DATA",
    data,
  };
}

export function saveBcfData(data, config) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/bcf/saveBcfData`,
        data,
        config,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(saveBcfId(response.data.data.id));
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function getCommentsData(data) {
  return async (dispatch) => {
    dispatch(itemsAreLoading(true));

    const responseData = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/comments/getCommentsData`,
      data,
    );
    if (responseData.data.status) {
      dispatch(setIssuesCommentsData(responseData.data.data, data.issueId));
      return responseData.data.data;
    }
  };
}

export function saveCommentsData(data, userData = {}) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/comments/saveCommentsData`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(
            setIssuesCommentsData(
              response.data.data,
              data.issueId,
              !!data.commentId,
            ),
          );
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function deleteCommentsData(data) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/comments/deleteCommentsData`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(deleteIssuesCommentsData(data.commentId, data.issueId));
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function getClashCommentsData(data) {
  return async (dispatch) => {
    dispatch(itemsAreLoading(true));

    const responseData = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/comments/getClashCommentsData`,
      data,
    );
    if (responseData.data.status) {
      dispatch(setIssuesCommentsData(responseData.data.data, data.resourceId));
    }
  };
}

export function saveClashCommentsData(data, userData = {}) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/comments/saveClashCommentsData`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(
            setIssuesCommentsData(
              {
                ...data,
                id: response.data.data.id,
                createdDate: response.data.data.createdDate,
                ...userData,
              },
              response.data.data.resourceId,
            ),
          );
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function deleteClashCommentsData(data, resourceId) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/comments/deleteClashCommentsData`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(deleteIssuesCommentsData(data.commentId, resourceId));
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function saveIssuesData(data, id, toast = true) {
  return async (dispatch) => {
    dispatch(itemsAreLoading(true));

    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/issues/saveIssuesData`,
      data,
    );

    if (response.data.status) {
      dispatch(mergeIssuesData({ ...response.data.data }, id));
      dispatch(
        mergeIssuesPinData({
          id: response.data.data.id,
          pinData: response.data.data.pinData,
        }),
      );
      const toastContent = id
        ? "Issue has been edited successfully!"
        : "Issue has been created successfully!";
      if (toast) {
        dispatch(
          showToastNotification({
            content: toastContent,
            type: TOAST_TYPE_SUCCESS,
          }),
        );
      }
    } else {
      dispatch(
        showToastNotification({
          content: "Something went wrong!",
          type: TOAST_TYPE_ERROR,
        }),
      );
    }

    return response.data.status;
  };
}

export async function deleteIssuesAttachedFile(data) {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/issues/removeFilesFromIssues`,
    data,
  );
  return response;
}

export function getIssuesData(data, returnValue = false) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/issues/getIssuesData`,
      data,
    );
    if (response.data.status) {
      dispatch(setIssuesData(response.data.result));
      if (response.data.count) {
        dispatch(setIssuesCount(response.data.count));
      }
    }
    if (returnValue) {
      return response.data.result;
    }
  };
}

export function getIssueViewpointData(data) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/issues/getIssueViewpointData`,
      data,
    );
    if (response.data.status) {
      dispatch(modifyIssuesData(response.data.result));
    }
  };
}

export function getfilteredIssuesData(data, returnValue = false) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/issues/getIssuesData`,
      data,
    );
    if (response.data.status) {
      dispatch(arrangeIssuesData(response.data.result));
    }
    if (returnValue) {
      return response.data.result;
    }
  };
}

export function getIssuesPinData(data) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/issues/getIssuesPinData`,
      data,
    );
    if (response.data.status) {
      dispatch(setIssuesPinData(response.data.result));
    }
  };
}

export function getFileData(data, returnValue = false) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/getFileData`,
      data,
    );
    if (returnValue) {
      return response.data.data;
    }
  };
}

export function getIssuesDataByPortal(data) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/issues/getIssuesDataByPortal`,
        data,
      )
      .then((response) => {
        dispatch(setIssuesData(response.data.result));
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export async function getCompleteIssuesDataByPortal(data) {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/issues/getIssuesDataByPortal`,
    data,
  );
  return response.data.result;
}

export function getIssuesStatusesData() {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .get(
        `${process.env.REACT_APP_CUBE_BACKEND}/issues/getAllStatusesForIssues`,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setIssuesStatusesData(response.data.data));
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function getTasksStatusesData() {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .get(`${process.env.REACT_APP_CUBE_BACKEND}/tasks/getAllStatusesForTasks`)
      .then((response) => {
        if (response.data.status) {
          dispatch(setTasksStatusesData(response.data.data));
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}
export function getEstimatesStatusesData() {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .get(
        `${process.env.REACT_APP_CUBE_BACKEND}/estimate/getAllStatusesForEstimates`,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setEstimatesStatusesData(response.data.data));
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function getFilesStatusesData(data) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .get(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileUpload/getAllStatusesForFiles`,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setFilesStatusesData(response.data.data));
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function getProjectsStatusesData(data) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .get(
        `${process.env.REACT_APP_CUBE_BACKEND}/projects/getAllStatusesForProjects`,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setProjectsStatusesData(response.data.data));
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function saveProjectStatus(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/projects/saveProjectStatus`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(
            showToastNotification({
              content: "Status changed successfully",
            }),
          );
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export function saveProjectAttachedPositionData(data) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/projects/saveProjectAssignedPosition`,
      data,
    );
    let toastFields;
    if (response.data.status) {
      toastFields = {
        content: "Assigned position changed successfully",
        type: TOAST_TYPE_SUCCESS,
      };
    } else {
      toastFields = {
        content: response.data.errorMessage,
        type: TOAST_TYPE_ERROR,
      };
    }
    dispatch(showToastNotification(toastFields));
  };
}

export function saveTaskStatus(data) {
  return async (dispatch) => {
    await axiosWrapper
      .post(`${process.env.REACT_APP_CUBE_BACKEND}/tasks/saveTaskStatus`, data)
      .then((response) => {
        if (response.data.status) {
          dispatch(updateTaskData(data));
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export async function deleteTask(data) {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/tasks/deleteTask`,
    data,
  );
  return response;
}

export async function saveProjectData(data) {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/projects/saveProjectsData`,
    data,
  );
  return response.data;
}

export function setZoomLevel(data) {
  return {
    type: "SET_ZOOM_LEVEL",
    data,
  };
}

export function setEditIssuesData(data) {
  return {
    type: "SET_EDIT_ISSUES_DATA",
    data,
  };
}

export function setAutoBcfLoaded(param) {
  return {
    type: "SET_AUTO_BCF_LOADED",
    param,
  };
}

export function getBcfData(data) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true));

    axiosWrapper
      .post(`${process.env.REACT_APP_CUBE_BACKEND}/bcf/getBcfData`, data)
      .then((response) => {
        if (response.data.status && response.data.data.bcfData) {
          dispatch(setBcfBufferData(response.data.data.bcfData));
          dispatch(saveBcfId(response.data.data.rowData.id));
        } else {
          dispatch(setAutoBcfLoaded(true));
        }
      })
      .catch(() => dispatch(itemsHaveError(true)));
  };
}

export async function getPdfMarkUpData(data) {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/issues/getPdfMarkUpData`,
    data,
  );
  return response;
}

export function successReadNotificationToast(selectedNotificationsId) {
  return async (dispatch) => {
    dispatch(
      showToastNotification({
        content: `Notification${
          selectedNotificationsId.length > 1 ? "s" : ""
        } successfully marked as read`,
        type: TOAST_TYPE_SUCCESS,
      }),
    );
  };
}

export async function getCommentsList(data) {
  return await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/comments/getCommentsData`,
    data,
  );
}
