import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { getNameInitials } from "../constants/globalFunctions";
import { CONSTANTS } from "../constants/globalVariable";
import IconComponent from "../icons";
import { ThemeContext } from "../../ProviderApp";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserProfileModal from "../../components/UserProfileModalComponent";
import { fetchUserTagData } from "../../actions/setUserTagData";
import ProviderApp from "../../ProviderApp";

function UserNameTagComponent({
  id,
  handleDeleteIconClicked,
  background = "var(--surface-white)",
}) {
  return (
    <ProviderApp>
      <UserNameTagComponentWithProvider
        id={id}
        background={background}
        handleDeleteIconClicked={handleDeleteIconClicked}
      />
    </ProviderApp>
  );
}

function UserNameTagComponentWithProvider({
  id,
  handleDeleteIconClicked,
  background,
}) {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { userTagData } = useSelector((state) => state);

  const [showUserProfileModal, setShowUserProfileModal] = useState(false);

  const handleClick = (e) => {
    setShowUserProfileModal(true);
  };

  const onClose = () => {
    setShowUserProfileModal(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchUserTagData(id));
    }
  }, [id]);

  return (
    <>
      {userTagData[id] && userTagData[id].data && userTagData[id].isLoaded && (
        <Chip
          label={userTagData[id].data.fullName}
          deleteIcon={<IconComponent name="cross" />}
          size="medium"
          avatar={
            userTagData[id].data.profilePic ? (
              <Avatar
                src={`${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getImage/${CONSTANTS.PROFILE}/${id}`}
              />
            ) : (
              <Avatar>
                <div className="text-white">
                  {getNameInitials({
                    firstName: userTagData[id].data.firstName,
                    lastName: userTagData[id].data.lastName,
                  })}
                </div>
              </Avatar>
            )
          }
          onDelete={handleDeleteIconClicked}
          onClick={handleClick}
          sx={{
            background: `var(${theme.userNameTagComponentBackground})`,
            color: `var(${theme.rootTextColor})`,
            // TODO: tag component label not working inside syncufusion grid component. Will remove it later when we remove syncufsion grids
            lineHeight: "normal",
          }}
        />
      )}
      {showUserProfileModal && (
        <UserProfileModal userId={id} onClose={onClose} />
      )}
    </>
  );
}

export default UserNameTagComponent;
